import React, { useEffect, useState } from 'react';
import './snoopy.css'
import IMG1 from '../../assets/snoopy_3.webp'
import IMG2 from '../../assets/snoopy_4.webp'

const Snoopy = () => {
  const calculateTimeLeft = () => {
    let today = new Date();
    let year = today.getFullYear();
    let birthday = new Date(`03/28/${year}`);
    
    // Check if today is after Snoopy's birthday in the current year
    if (today > birthday) {
      // Calculate next year's birthday
      year++;
      birthday = new Date(`03/28/${year}`);
    }

    let difference = +birthday - +today;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <section id='snoopy'>
      <h5>My super cute dog</h5>
      <h2>Snoopy!!!</h2>

      <div className="container snoopy__container">

        <article className='snoopy__item'>
          <h3>This is Snoopy!</h3>
          <img src={IMG1} alt="" />
          <small className='text-light'>He is a Jack Russel, Corgi, Shizu mix! <br></br></small>
          <small className='text-light'>He is 6 years old</small>
          <div className="snoopy__item-cta">
          </div>
        </article>

        <article className='snoopy__item'>
          <h3>BDAY Countdown</h3>
          <img src={IMG2} alt="" />
          {timerComponents.length ? timerComponents : <span>Time Error! Please notify Athena</span>}
          <div className="snoopy__item-cta">
        
          </div>
        </article>

      </div>
    </section>
  );
};

export default Snoopy;
