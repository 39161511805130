import React from 'react'
import './socials.css'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'
import {BsDiscord} from 'react-icons/bs'
import {BsTwitch} from 'react-icons/bs'
import {BsShop} from 'react-icons/bs'
import {BsLink} from 'react-icons/bs'
import {BsMailbox} from 'react-icons/bs'
import {BsGlobe} from 'react-icons/bs'

const Socials = () => {
  return (
    <section id='socials'>

      <h5>Give me a follow</h5>
      <h2>My socials</h2>

      <div className="container socials__container">

        <div className="socials__dev">
            <h3>Dev Socials</h3>

            <div className="socials__content">

              <article className='socials__details'>
                <BsGithub className='socials__details-icon'/>
                <div>
                  <h4>GitHub</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/github" target="_blank" rel="noreferrer">athenarebeldev 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsDiscord className='socials__details-icon'/>
                <div>
                  <h4>Discord</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/discord" target="_blank" rel="noreferrer">Join Server 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsMailbox className='socials__details-icon'/>
                <div>
                  <h4>Mail</h4>
                  <small className='text-light'><a href="mailto:hello@athenarebel.dev">Send an Email 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsLink className='socials__details-icon'/>
                <div>
                  <h4>Partner Links</h4>
                  <dl>
                  <dd><small className='text-light'><a href="https://link.fourthwall.com/snooperstore" target="_blank" rel="noreferrer">Join Fourthwall 🔗</a></small></dd>
                  <dd><small className='text-light'><a href="https://hostinger.com?REFERRALCODE=1ATHENA53" target="_blank" rel="noreferrer">Host Websites 🔗</a></small></dd>
                  </dl>
                </div>
              </article>
            </div>
        </div>
     {/* End of main soc */}
     <div className="socials__main">
            <h3>Personal Socials</h3>

            <div className="socials__content">

              <article className='socials__details'>
                <BsInstagram className='socials__details-icon'/>
                <div>
                  <h4>Instagram</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/instagram" target="_blank" rel="noreferrer">Drop a Follow 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsTwitch className='socials__details-icon'/>
                <div>
                  <h4>Twitch</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/twitch" target="_blank" rel="noreferrer">Watch me Live 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsDiscord className='socials__details-icon'/>
                <div>
                  <h4>Discord</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/discord" target="_blank" rel="noreferrer">Join Server 🔗</a></small>
                </div>
              </article>
              <article className='socials__details'>
                <BsShop className='socials__details-icon'/>
                <div>
                  <h4>Merch</h4>
                  <small className='text-light'><a href="https://socials.athenarebel.dev/merch" target="_blank" rel="noreferrer">Come Shop 🔗</a></small>
                </div>
              </article>
            </div>
        </div>
      </div>
      <div className="socials__icons">
      <a href="https://status.athenarebel.dev/" target="_blank" rel="noreferrer"><BsGlobe/></a>
          <a href="https://socials.athenarebel.dev/instagram" target="_blank" rel="noreferrer"><BsInstagram/></a>
          <a href="https://socials.athenarebel.dev/github" target="_blank" rel="noreferrer"><BsGithub/></a>
          <a href="https://socials.athenarebel.dev/merch" target="_blank" rel="noreferrer"><BsShop/></a>
        </div>
    </section>
  )
}

export default Socials