import { useEffect, useState } from 'react';
import 'intersection-observer';

function useActiveNav(ids) {
  const [activeNav, setActiveNav] = useState(ids[0]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveNav(`#${entry.target.id}`);
          }
        });
      },
      { threshold: 0.5 }
    );

    ids.forEach((id) => {
      const element = document.getElementById(id.substring(1));
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      ids.forEach((id) => {
        const element = document.getElementById(id.substring(1));
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [ids]);

  return activeNav;
}

export default useActiveNav;
