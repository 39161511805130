import React from 'react';
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { FaLaptopCode } from 'react-icons/fa';
import { GoChecklist } from 'react-icons/go';
import { BsShare } from 'react-icons/bs';
import useActiveNav from './useActiveNav';

const Nav = () => {
  const ids = ['#home', '#about', '#experience', '#myprojects', '#socials'];
  const activeNav = useActiveNav(ids);

  return (
    <nav>
      <a href="#home" className={activeNav === '#home' ? 'active' : ''}>
        <AiOutlineHome />
      </a>
      <a href="#about" className={activeNav === '#about' ? 'active' : ''}>
        <BiUser />
      </a>
      <a href="#experience" className={activeNav === '#experience' ? 'active' : ''}>
        <FaLaptopCode />
      </a>
      <a href="#myprojects" className={activeNav === '#myprojects' ? 'active' : ''}>
        <GoChecklist />
      </a>
      <a href="#socials" className={activeNav === '#socials' ? 'active' : ''}>
        <BsShare />
      </a>
    </nav>
  );
};

export default Nav;
