import React from 'react'
import './footer.css'
import {BsInstagram} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsShop} from 'react-icons/bs'
import {BsGlobe} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer id='footer'>
      <div className="footer__socials">
          <a href="https://status.athenarebel.dev/" target="_blank" rel="noreferrer"><BsGlobe/></a>
          <a href="https://socials.athenarebel.dev/instagram" target="_blank" rel="noreferrer"><BsInstagram/></a>
          <a href="https://socials.athenarebel.dev/github" target="_blank" rel="noreferrer"><BsGithub/></a>
          <a href="https://socials.athenarebel.dev/merch" target="_blank" rel="noreferrer"><BsShop/></a>
        </div>
        <div className="footer__copyright">
            <small>Made with ❤️ by : AthenaRebel</small><br></br>
            <small>&copy; AthenaRebelDev. All rights reserved.</small>
        </div>
    </footer>
  )
}

export default Footer