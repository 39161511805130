import React, { useState } from 'react';
import './about.css';
import myImage1 from '../../assets/my-image1.webp';
import myImage2 from '../../assets/my-image2.webp';
import myImage3 from '../../assets/my-image3.webp';
import { GiGraduateCap } from 'react-icons/gi';
import { BsDatabaseFillGear } from 'react-icons/bs';
import { TfiWorld } from 'react-icons/tfi';

const About = () => {
  const images = [myImage1, myImage2, myImage3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleClick = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  return (
    <section id='about'>
      
      <h5>Who's Athena?</h5>
      <h2>About Me</h2>

      <div className="container about__container">

          <div className="about__me">
            <div className="about__me-image">
                <img
                  src={images[currentImageIndex]}
                  alt="Me"
                  onClick={handleClick}
                  className="image-transition"
                />
            </div>
          </div>


          <div className="about__content">
            <div className="about__cards">

              <article className='about__card'>
                  <GiGraduateCap className='about__icon'/>
                  <h5>Degrees</h5>
                  <h6>Work In Progress <br /></h6>
                  <small>AA. Cybersecurity <br /></small>
                  <small>Bsc. Comp Engineering <br /></small>
              </article>

              <article className='about__card'>
                  <BsDatabaseFillGear className='about__icon'/>
                  <h5>Projects</h5>
                  <small>
                    <ul>
                      <li>Fourthwall</li>
                      <li>Arcaneix</li>
                      <li>USC</li>
                    </ul>
                  </small>
              </article>

              <article className='about__card'>
                  <TfiWorld className='about__icon'/>
                  <h5>Domains</h5>
                  <small>
                    <ul>
                      <li>athenarebel.dev</li>
                      <li>atxyz.fun</li>
                    </ul>
                  </small>
              </article>

            </div>
            <ul>
              <li>⠀</li>
              <li> I'm a university student studying <b>Cybersecurity</b> for my associates degree and <b>Computer Engineering</b> for my bachelors. I'm currently working on various projects, focusing on <b>Discord Bots</b> and <b>Website Development</b>. </li>
              <li>⠀</li>
              <li> Aside from my studies, I'm an animal lover and a big fan of raccoons. I also have a super cute dog named <a href="https://snoopy.athenarebel.dev/" target="_blank" rel="noreferrer">Snoopy</a>! <br></br> </li>    
              <li>⠀</li>
              <li> I am a proud <span className="rainbow-text">LGBTQIA+</span> dev and I'm always on the lookout for exciting new projects to dive into. I own several domains and love exploring new opportunities for collaboration. <br></br> </li>    
            </ul>

          </div>
      </div>
    </section>
  )
}

export default About;
