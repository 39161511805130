import React from 'react'
import './header.css'
import CTA from './CTA'
import me from '../../assets/loading_1.gif'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    
    
      <header id='home'>        
        <div className="container header__container">

          <h5>Howdy! Welcome to my site 💙</h5>
          <h1>C:\Users\AthenaRebel\Portfolio &gt; <span className="cursor-flash">|</span></h1>
          <h1 className='mobile__header'>AthenaRebel Portfolio</h1>
          <h5 className="text-light">Raccoon Enthusiast & Freelance Web Developer</h5>
          <CTA/>
          <HeaderSocials/>

          <div className="me">

          <img src={me} alt='main icon' style={{ width: '400px', height: '390px' }} />


          </div>

          <a href="#about" className='scroll__down'>Scroll Down &#8594;</a>
        </div>
      </header>

    
    
  )
}

export default Header