import React from 'react'
import './myprojects.css'
import IMG1 from '../../assets/banner_portfolio.webp'
import IMG2 from '../../assets/banner_atxyz.webp'
import IMG3 from '../../assets/banner_usc.webp'
import IMG4 from '../../assets/banner_mothyisafurry.webp'
import IMG5 from '../../assets/banner_stardust.webp'
import IMG6 from '../../assets/banner_thedreddzone.webp'
import IMG7 from '../../assets/banner_fourthwall.webp'
import IMG8 from '../../assets/banner_tutorialatxyz.webp'

const Myprojects = () => {
  return (
    <section id='myprojects'>

      <h5>My Recent Works</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG1} alt="" />
            </div>
            <h3>Portfolio Site (This Site)</h3>
            <small className='text-light'>ReactJS | CSS | JS | HTML<br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
            <a href="/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
            <a href="https://old.athenarebel.dev" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Old Website</a>  
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG2} alt="" />
            </div>
            <h3>Project Website (Coming Soon)</h3>
            <small className='text-light'>ReactJS | CSS | JS | HTML | MySQL<br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
            <a href="https://atxyz.dev/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG8} alt="" />
            </div>
            <h3>Tutorial Website</h3>
            <small className='text-light'>ReactJS | CSS | JS | HTML <br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
            <a href="https://learn.atxyz.dev/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG2} alt="" />
            </div>
            <h3>Resume Builder (Coming Soon)</h3>
            <small className='text-light'>ReactJS | CSS | JS | HTML <br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
            <a href="https://soon.atxyz.dev" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG5} alt="" />
            </div>
            <h3>Stardust Fur Website & Merch</h3>
            <small className='text-light'>HTML | CSS | JS | FourthwallAPI<br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
              <a href="https://stardustfur.xyz/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG3} alt="" />
            </div>
            <h3>Unknownsignalcatalogue</h3>
            <small className='text-light'>ReactJS | HTML | CSS | JS | MySQL | DiscordJS<br></br></small>
            <small className='text-light'>Lead Developer</small>
            <div className="portfolio__item-cta">
              <a href="https://uscsite.net/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
              <a href="https://discord.gg/sJNWVVKa9A" target="_blank" rel='noreferrer' className='btn btn-primary'>Join Discord</a>  
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG4} alt="" />
            </div>
            <h3>MothyDanger Site</h3>
            <small className='text-light'>HTML | CSS | JS | MySQL<br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
              <a href="https://www.mothyisafurry.fun/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
              <a href="https://www.twitch.tv/mothydanger/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Twich</a> 
            </div> 
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG6} alt="" />
            </div>
            <h3>The Dredd Zone</h3>
            <small className='text-light'>HTML | CSS | JS | FourthwallAPI<br></br></small>
            <small className='text-light'>Web Developer</small>
            <div className="portfolio__item-cta">
              <a href="https://frostbrightproductions.com" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
              <a href="https://thedredd.zone/" target="_blank" rel='noreferrer' className='btn btn-primary'>Shop Merch</a>
            </div>
        </article>

        <article className='portfolio__item'>
            <div className="portfolio__item-image">
                <img src={IMG7} alt="" />
            </div>
            <h3>Fourthwall</h3>
            <small className='text-light'>DiscordJS | MySQL<br></br></small>
            <small className='text-light'>Bot Developer</small>
            <div className="portfolio__item-cta">
              <a href="https://fourthwall.com/" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a> 
              <a href="https://discord.gg/t5Vm6Q2425" target="_blank" rel='noreferrer' className='btn btn-primary'>Join Discord</a>  
            </div>
        </article>


      </div>
    </section>
  )
}

export default Myprojects